import React, { useState, createContext, useContext } from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import Product from "./pages/Product/Product";
import Products from "./pages/Products/Products";
import AboutPage from "./pages/About/AboutPage";
import ContactUs from "./pages/ContactUs/ContactUs";
import Deliveries from "./pages/Deliveries/Deliveries";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import PopUp from "./components/Contact/ContactPopUp"; // Import the PopUp component
import "./app.scss";

// Create a context for the popup
const PopupContext = createContext();

// Custom hook to use the popup context
export const usePopup = () => useContext(PopupContext);

const Layout = () => {
  const [popupInfo, setPopupInfo] = useState({ isOpen: false, message: {} });

  const showPopup = (message) => {
    setPopupInfo({ isOpen: true, message });
  };

  const closePopup = () => {
    setPopupInfo({ isOpen: false, message: {} });
  };

  return (
    <PopupContext.Provider value={{ showPopup, closePopup }}>
      <div className="app">
        <Navbar />
        <Outlet />
        <Footer />
        <PopUp
          isOpen={popupInfo.isOpen}
          onClose={closePopup}
          message={popupInfo.message}
        />
      </div>
    </PopupContext.Provider>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/termsofuse",
        element: <TermsOfUse />,
      },
      {
        path: "/deliveries",
        element: <Deliveries />,
      },
      {
        path: "/contactUs",
        element: <ContactUs />,
      },
      {
        path: "/products/:id",
        element: <Products />,
      },
      {
        path: "/product/:id",
        element: <Product />,
      },
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;