import React from 'react';
import styles from './TermsOfUse.module.scss';

const TermsOfUse = () => {
  return (
    <div className={styles.termsPage} dir="rtl">
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>תקנון פרטיות</h1>
        
        <section className={styles.section}>
          <h2>הקדמה</h2>
          <p>
            הפרטיות של המבקרים באתר שלנו חשובה לנו מאוד, ואנחנו מחויבים לשמירה עליה. המדיניות הזו מסבירה מה נעשה עם הפרטים שנאסף באתר.
            השימוש באתר כרוך בהסכמה לשימוש בעוגיות ותגי מעקב בהתאם לתנאי המדיניות הנ"ל.
          </p>
        </section>
        
        <section className={styles.section}>
          <h2>איסוף של מידע אישי</h2>
          <p>להלן סוגי מידע אישי שאנחנו עשויים לאסוף, לאחסן, ולהשתמש בו:</p>
          <ul>
            <li>מידע על המחשב שלך כולל כתובת ה-IP שלך, המיקום הגאוגרפי, סוג הדפדפן וגרסתו ומערכת ההפעלה</li>
            <li>מידע על ביקוריך באתר ושימוש בו כולל מקור ההפניה, משך הביקור, צפיות בדפים ונתיבי ניווט באתר</li>
            <li>מידע, כגון כתובת הדוא"ל שלך, שתזין כשאתה נרשם לאתר שלנו</li>
            <li>מידע שאתה מזין כשאתה יוצר פרופיל באתר שלנו</li>
            <li>מידע שנוצר בזמן השימוש באתר שלנו</li>
            {/* Add more list items as needed */}
          </ul>
        </section>

        <section className={styles.section}>
          <h2>שימוש במידע אישי</h2>
          <p>בפרטים אישיים שנמסרו לנו דרך האתר שלנו ייעשה שימוש למטרות שצוינו במדיניות זו, או בעמודים הרלוונטיים שבאתר. ייתכן שנשתמש בפרטים האישיים שלכם למטרות הבאות:</p>
          <ul>
            <li>ניהול אתר האינטרנט והעסק שלנו</li>
            <li>התאמה אישית של אתר האינטרנט שלנו עבורך</li>
            <li>לאפשר לך לעשות שימוש בשירותים הזמינים באתר שלנו</li>
            <li>שליחת סחורה שנרכשה דרך אתר האינטרנט שלנו</li>
            {/* Add more list items as needed */}
          </ul>
        </section>

        {/* Add more sections for other parts of the terms */}

        <section className={styles.section}>
          <h2>מדיניות ביטול עסקה</h2>
          <p>
            ניתן לבטל עסקה למוצרים בעיצוב קיים שלנו. החזרות ניתנות עד 14 ימים מיום קבלת המשלוח ובתנאי שהפריטים חדשים ולא נעשה בפריטים כל שימוש. ניתן לבצע החזרה פיזית בחנות בת״א או באמצעות דואר רשום.
          </p>
          <p>
            כתובת למשלוח: ג'אסט פרינט - בן יהודה 218 ת״א, בצירוף פרטי הלקוחה, טלפון לחזרה, קבלת קנייה/ צילום קבלת הקנייה. אנו נחליף את המוצר או נחזיר תשלום בניכוי דמי המשלוח.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfUse;