import React from 'react';
import styles from './Deliveries.module.scss';

const Deliveries = () => {
  return (
    <div className={styles.deliveriesPage} dir="rtl">
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>מידע על משלוחים וביטולים</h1>
        
        <section className={styles.section}>
          <h2>אפשרויות משלוח</h2>
          <ul>
            <li>
              <strong>איסוף עצמי</strong>
              <p>מחיר: ללא עלות</p>
              <p>איסוף מהחנות בכתובת - בן יהודה 218, ת"א</p>
            </li>
            <li>
              <strong>שרות משלוחים לנקודת איסוף קרוב לבית הלקוח: BOXIT</strong>
              <p>מחיר: 20 ש"ח</p>
              <p>זמן אספקה: עד 4 ימים לכל הארץ</p>
            </li>
            <li>
              <strong>שליח עד הבית</strong>
              <p>מחיר: 40 ש"ח</p>
              <p>זמן אספקה: עד 4 ימים לכל הארץ</p>
            </li>
          </ul>
        </section>
        
        <section className={styles.section}>
          <h2>מדיניות ביטול עסקה</h2>
          <p>
            ניתן לבטל עסקה למוצרים בעיצוב קיים שלנו. החזרות ניתנות עד 14 ימים מיום קבלת המשלוח ובתנאי שהפריטים חדשים ולא נעשה בפריטים כל שימוש. ניתן לבצע החזרה פיזית בחנות בת״א או באמצעות דואר רשום.
          </p>
          <p>
            כתובת למשלוח: ג'אסט פרינט - בן יהודה 218 ת״א, בצירוף פרטי הלקוחה, טלפון לחזרה, קבלת קנייה/ צילום קבלת הקנייה. אנו נחליף את המוצר או נחזיר תשלום בניכוי דמי המשלוח.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Deliveries;