import React, { useState } from "react";
import "./Contact.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import PinterestIcon from "@mui/icons-material/Pinterest";
import { usePopup } from "../../App"; // Import the usePopup hook

const Contact = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { showPopup } = usePopup(); // Use the popup hook

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  const handleSubmit = () => {
    if (validateEmail(email)) {
      showPopup({
        title: "תודה על הצטרפותך!",
        body: "נוספת בהצלחה לרשימת התפוצה שלנו"
      });
      setError("");
      setEmail("");
    } else {
      setError("אנא הזן כתובת אימייל חוקית");
    }
  };

  return (
    <div className="contact" dir="rtl">
      <div className="wrapper">
        <span className="title">צרו איתנו קשר ונשמח לעזור לכם</span>
        <div className="mail">
          <input
            type="text"
            placeholder="הכנס מייל"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={handleSubmit}>הצטרפו אלינו</button>
        </div>
        {error && <p className="error">{error}</p>}
        <div className="icons">
          <FacebookIcon />
          <InstagramIcon />
          <TwitterIcon />
          <GoogleIcon />
          <PinterestIcon />
        </div>
      </div>
    </div>
  );
};

export default Contact;