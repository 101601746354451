import React from 'react';
import styles from '../About/AboutPage.module.scss'
import aboutImg from '../../Media/aboutP.jpg'
import aboutProductImg from '../../Media/aboutPproducts.jpg'
import AboutPCrew from '../../Media/aboutPCrew.png'

const AboutPage = () => {
  return (
    <div className={styles.aboutPage} dir="rtl">
      <h1 className={styles.title}>אז מי בעצם אנחנו ואיך הכל התחיל? </h1>
      
      <section className={styles.section}>
        <div className={styles.content}>
          <h2>עלינו</h2>
          <p>
            JUST PRINT LTD היא חברה מובילה בתחום ההדפסות על טקסטיל, עם ניסיון עשיר של מעל 20 שנה בצרפת. לאחרונה, החברה הרחיבה את פעילותה לישראל, בניהולה של המנכ"לית מרין מולאים.
          </p>
          <p>
            הידע הרחב שלנו בבדים ובשיטות הדפסה מתקדמות מאפשר לנו לספק פתרונות מדויקים ואמינים ללקוחותינו, תוך שמירה על סטנדרטים גבוהים של איכות ושירות.
          </p>
        </div>
        <img src={aboutImg} alt="JUST PRINT בפעולה" className={styles.image} />
      </section>
      
      <section className={styles.section}>
        <img src={aboutProductImg} alt="מגוון מוצרי JUST PRINT" className={styles.image} />
        <div className={styles.content}>
          <h2>במה אנחנו מתמחים?</h2>
          <ul>
            <li>הדפסות בכמויות קטנות, בינוניות וגדולות</li>
            <li>מיתוג וקידום עסקים באמצעות ביגוד ייחודי</li>
            <li>יצירת מוצרי פרסום מותאמים אישית</li>
            <li>הלבשה לאירועי ספורט, הפקות פרטיות, סמינרים וכנסים</li>
            <li>שימוש בחומרים איכותיים ובטכנולוגיות מתקדמות</li>
          </ul>
        </div>
      </section>
      
      <section className={styles.section}>
        <div className={styles.content}>
          <h2>למה לבחור בנו?</h2>
          <ul>
            <li>מבחר רחב של מוצרי טקסטיל להדפסה</li>
            <li>מגוון טכניקות הדפסה מתקדמות</li>
            <li>ייעוץ מקצועי בבחירת שיטת ההדפסה המתאימה</li>
            <li>מחירים תחרותיים בהזמנות גדולות</li>
            <li>מהירות תגובה וזמני אספקה קצרים</li>
            <li>שירות ברמה אירופאית עם חיוך ישראלי חם</li>
          </ul>
        </div>
        <img src={AboutPCrew} alt="צוות JUST PRINT" className={styles.image} />
      </section>
      
      <blockquote className={styles.quote}>
        "בין אם אתה אדם פרטי, עמותה, מועדון ספורט או חברה, אנו מוצאים את המוצר המותאם אישית העונה על הציפיות שלך." - מרין, מנכ"לית JUST PRINT ישראל
      </blockquote>
    </div>
  );
};

export default AboutPage;