import React, { useState } from "react";
import "./Cart.scss";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { removeItem, resetCart } from "../../redux/cartReducer";
import { makeRequest } from "../../makeRequest";
import { loadStripe } from "@stripe/stripe-js";
import PaymentWindow from '../../components/PaymentWindow/PaymentWindow'

const Cart = ({ onClose }) => {
  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const [showPaymentWindow, setShowPaymentWindow] = useState(false);

  const totalPrice = () => {
    let total = 0;
    products.forEach((item) => {
      total += item.quantity * item.price;
    });
    return total.toFixed(2);
  };

  const stripePromise = loadStripe(
    "pk_test_eOTMlr8usx1ctymXqrik0ls700lQCsX2UB"
  );

  const handlePayment = () => {
    setShowPaymentWindow(true);
  };

  return (
    <div className="cart" dir="rtl">
      <div className="cart-header">
        <h1>המוצרים בעגלת הקניות שלי</h1>
        {/* <button className="close-button" onClick={onClose}>
          <CloseIcon />
          
        </button> */}
      </div>
      {products?.map((item) => (
        <div className="item" key={item.id}>
          <img src={process.env.REACT_APP_UPLOAD_URL + item.img} alt="" />
          <div className="details">
            <h1>{item.title}</h1>
            <p>{item.desc?.substring(0, 100)}</p>
            <div className="price">
              {"₪"+item.price}  {item.quantity+"x "}     
            </div>
          </div>
          <DeleteOutlinedIcon
            className="delete"
            onClick={() => dispatch(removeItem(item.id))}
          />
        </div>
      ))}
      <div className="total">
        <span>הסכום לתשלום</span>
        <span>₪{totalPrice()}</span>
      </div>
      <button onClick={handlePayment}>המשך לתשלום</button>
      <div className="actions">
        <span className="reset" onClick={() => dispatch(resetCart())}>
          אפס את עגלת הקניות
        </span>
        <span className="close" onClick={onClose}>
          סגור
        </span>
      </div>
      {showPaymentWindow && (
        <PaymentWindow 
          onClose={() => setShowPaymentWindow(false)}
          totalAmount={totalPrice()}
          onPaymentComplete={() => {
            // Handle payment completion
            dispatch(resetCart());
            setShowPaymentWindow(false);
            onClose();
          }}
        />
      )}
    </div>
  );
};

export default Cart;