import React, { useState, useEffect } from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./Slider.scss";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const data = [
    "https://justprint.co.il/cdn/shop/collections/pic_bodedim_1512x.png",
    "https://justprint.co.il/cdn/shop/collections/TAB_AD_1512x.jpg",
    "https://justprint.co.il/cdn/shop/collections/birthday2_1512x.jpg",
  ];

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? 2 : (prev) => prev - 1);
  };
  const nextSlide = () => {
    setCurrentSlide(currentSlide === 2 ? 0 : (prev) => prev + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 500000);

    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <div className="slider">
      <div className="container" style={{transform:`translateX(-${currentSlide * 100}vw)`}}>
        <div className="slide-container">
          <img src={data[0]} alt="" />
          <div className="custom-text custom-text2">
            <span className="black-text">JustPrint</span>
            <span className="white-text">! מדפיסים גם על חולצות</span>
          </div>  
        </div>  

        <div className="slide-container">
          <img src={data[1]} alt="" />
          <div className="custom-text custom-text3">
            <span className="black-text">JustPrint</span>
            <span className="white-text">! הדפסה על בגדי עבודה</span>
          </div>  
        </div>      
  
        
        <div className="slide-container">
          <img src={data[2]} alt="" />
          <div className="custom-text custom-text1">
            <span className="black-text">JustPrint</span>
            <span className="white-text">! מדפיסים גם על כובעים</span>
          </div>  
        </div>
      </div>
      <div className="icons">
        <div className="icon" onClick={prevSlide}>
          <WestOutlinedIcon />
        </div>
        <div className="icon" onClick={nextSlide}>
          <EastOutlinedIcon />
        </div>
      </div>
    </div>
  );
};

export default Slider;