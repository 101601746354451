import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer" dir="rtl">
      <div className="top">
        <div className="item">
          <h1>קטגוריות</h1>
          <Link className="link" to="/products/1"><span>נשים</span></Link>
          <Link className="link" to="/products/2"><span>גברים</span></Link>
          <Link className="link" to="/products/3"><span>ילדים</span></Link>
          <Link className="link" to="/products/4"><span>אביזרים</span></Link>
        </div>
        <div className="item">
          <h1>קישורים</h1>
          <Link className="link" to="/contactUs"><span>הזמנות</span></Link>
          <Link className="link" to="/deliveries"><span>משלוחים</span></Link>
          <span>החלפות</span>
          <Link className="link" to="/termsofuse"><span>תקנון</span></Link>
        </div>
        <div className="item">
          <Link className="link" to="/about"><h1>עלינו</h1></Link>
          <span>
            חברת "just print" עוסקת בתחום ומומלץ לרכוש אצלה חולצות ומוצרים אחרים. שירותיה כוללים ייעוץ, גימור מושלם, מחירים נוחים, עמידה בלוח זמנים, משלוח ועוד. מנהליה הינם בעלי ידע רב וניסיון מצטבר המגיעים, בין היתר, ממתן שירותים איכותיים ללקוחות רבים לאורך זמן ועוד. הם ישמחו להעמיד את התשתית הקיימת אצלם לאחרים
          </span>
        </div>
        <div className="item">
          <Link className="link" to="/contactUs"><h1>צור קשר</h1></Link>
          <span>
            נשמח לעמוד לשירותכם! לפניות, שאלות או הזמנות, אתם מוזמנים ליצור קשר עם צוות Just Print. אנחנו כאן כדי לעזור לכם להדפיס את הרעיון שלכם על החולצה המושלמת.

            טופס יצירת קשר זמין באתר, או שתוכלו לפנות אלינו ישירות במייל או בטלפון.
          </span>
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <span className="logo">just print</span>
          <span className="copyright">
            האתר נבנה  ועוצב על ידי  
            <a href="https://codesuits.com" target="_blank" rel="noopener noreferrer">CodeSuits</a>
          </span>
        </div>
        <div className="right">
          <img src="/img/payment.png" alt="Payment methods" />
        </div>
      </div>
    </div>
  );
};

export default Footer;