import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import "./Navbar.scss"
import Cart from "../Cart/Cart";
import { useSelector } from "react-redux";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const products = useSelector((state) => state.cart.products);

  const handleCartToggle = () => {
    setOpen(!open);
  }

  const handleCloseCart = () => {
    setOpen(false);
  }

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  return (
    <div className="navbar" dir="rtl">
      <div className="wrapper">
        <div className="left">
          <div className="item logo-item">
            <Link className="link" to="/">
              <img 
                src="https://www.justprint.co.il/cdn/shop/files/logo_transparent_ee102ad0-404b-4610-bc79-61c4316fc39b_100x100.png" 
                alt="JustPrint Logo" 
                className="logo"
              />
            </Link>
          </div>
          <div className="desktop-menu">
            <div className="item">
              <Link className="link" to="/products/2">גברים</Link>
            </div>
            <div className="item">
              <Link className="link" to="/products/1">נשים</Link>
            </div>
            <div className="item">
              <Link className="link" to="/products/3">ילדים</Link>
            </div>
            <div className="item">
              <Link className="link" to="/products/10">אביזרים</Link>
            </div>
          </div>
        </div>
        <div className="center">
          {/* Center content if needed */}
        </div>
        <div className="right">
          <div className="desktop-menu">
            <div className="item">
              <Link className="link" to="/">ראשי</Link>
            </div>
            <div className="item">
              <Link className="link" to="/about">עלינו</Link>
            </div>
            <div className="item">
              <Link className="link" to="/contactUs">צור קשר</Link>
            </div>
          </div>
          <div className="phonenum">
            <div>03-7984948</div>
          </div>
          <div className="icons">
            <div className="cartIcon" onClick={handleCartToggle}>
              <ShoppingCartOutlinedIcon/>
              <span>{products.length}</span>
            </div>
          </div>
          <div className="mobile-menu-toggle" onClick={toggleMobileMenu}>
            {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </div>
        </div>
      </div>
      {open && <Cart onClose={handleCloseCart} />}
      <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
        <div className="item">
          <Link className="link" to="/products/2" onClick={toggleMobileMenu}>גברים</Link>
        </div>
        <div className="item">
          <Link className="link" to="/products/1" onClick={toggleMobileMenu}>נשים</Link>
        </div>
        <div className="item">
          <Link className="link" to="/products/3" onClick={toggleMobileMenu}>ילדים</Link>
        </div>
        <div className="item">
          <Link className="link" to="/products/10" onClick={toggleMobileMenu}>אביזרים</Link>
        </div>
        <div className="item">
          <Link className="link" to="/" onClick={toggleMobileMenu}>ראשי</Link>
        </div>
        <div className="item">
          <Link className="link" to="/about" onClick={toggleMobileMenu}>עלינו</Link>
        </div>
        <div className="item">
          <Link className="link" to="/contactUs" onClick={toggleMobileMenu}>צור קשר</Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;