import React from 'react'
import Categories from '../../components/Categories/Categories'
import Contact from '../../components/Contact/Contact'
import FeaturedProducts from '../../components/FeaturedProducts/FeaturedProducts'
import Slider from '../../components/Slider/Slider'
import "./Home.scss"
import LogoContainer from '../../components/LogoContainer/LogoContainer'
import StackedCarousel from '../../components/StackedCarousel/DeckCarousel'
import DeckCarousel from '../../components/StackedCarousel/DeckCarousel'
import CustomBanner from '../../components/CustomBanner/CustomBanner'

const Home = () => {
  return (
    <div className='home' >
      {/* <LogoContainer /> */}
      <Slider/>
      <Categories/>
      
      <DeckCarousel/>
      
      <FeaturedProducts type="featured"/>
      <CustomBanner/>
      
      
      {/* <FeaturedProducts type="trending"/> */}
      <Contact/>
    </div>
  )
}

export default Home