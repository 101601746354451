import React from "react";
import "./List.scss";
import Card from "../Card/Card";
import useFetch from "../../hooks/useFetch";

const List = ({ filters, sort, catId }) => {
  const queryString = React.useMemo(() => {
    const baseQuery = `/products?populate=*`;
    const filterQueries = [];

    if (catId) {
      filterQueries.push(`[filters][categories][id]=${catId}`);
    }

    if (filters.sub_categories) {
      filters.sub_categories.id.$in.forEach(subCat => {
        filterQueries.push(`[filters][sub_categories][id][$eq]=${subCat}`);
      });
    }

    if (filters.price) {
      filterQueries.push(`[filters][price][$lte]=${filters.price.$lte}`);
    }

    const filterString = filterQueries.join('&');
    const sortString = sort ? `&sort=price:${sort}` : '';

    return `${baseQuery}${filterString ? '&' + filterString : ''}${sortString}`;
  }, [filters, sort, catId]);

  const { data, loading, error } = useFetch(queryString);

  return (
    <div className="list">
      {loading
        ? "loading"
        : error
        ? "אופסי זה לא אתה זה אנחנו, מתנצלים על התקלה אנחנו על זה"
        : data?.map((item) => <Card item={item} key={item.id} />)}
    </div>
  );
};

export default List;
