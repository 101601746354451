import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

// Import your custom SCSS module
import styles from './DeckCarousel.module.scss';

const products = [
  { 
    id: 1,
    image: 'https://www.justprint.co.il/cdn/shop/products/1_f10751ad-6935-4038-aab7-f3bbf387a49e_300x300.jpg',
    route: '/product/5'
  },
  { 
    id: 2,
    image: 'https://www.justprint.co.il/cdn/shop/products/V2_1679426c-8fe5-4016-b14a-409aa55df49b_300x300.jpg',
    route: '/product/23'
  },
  { 
    id: 3,
    image: 'https://www.justprint.co.il/cdn/shop/products/K364_2018_a6b96e57-4727-489d-b8eb-d62d5f1b8b60_300x300.jpg',
    route: '/product/10'
  },
  { 
    id: 4,
    image: 'https://www.justprint.co.il/cdn/shop/products/2_6ea6aa8d-e58b-43f9-9f72-c603bcce8d15_300x300.jpg',
    route: '/product/24'
  },
  { 
    id: 5,
    image: 'https://www.justprint.co.il/cdn/shop/files/K3008-06_2023_300x300@2x.jpg',
    route: '/product/1'
  },
];

function DeckCarousel() {
  return (
    <div className={styles.deckCarouselWrapper}>
      <div className={styles.container}>
        <h1 className={styles.heading}>מה אפשר למצוא אצלינו</h1>
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          navigation={true}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          className={styles.swiper_container}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          loop={true}
        >
          {products.map((product) => (
            <SwiperSlide key={product.id} className={styles['swiper-slide']}>
              <Link to={product.route} className={styles.cardLink}>
                <div className={styles.cardFrame}>
                  <div className={styles.recommendedLabel}>המומלצים</div>
                  <img src={product.image} alt={`product_${product.id}`} />
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default DeckCarousel;