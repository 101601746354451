import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import List from "../../components/List/List";
import useFetch from "../../hooks/useFetch";
import "./Products.scss";

const Products = () => {
  const catId = parseInt(useParams().id);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [sort, setSort] = useState(null);
  const [selectedSubCats, setSelectedSubCats] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentImage, setCurrentImage] = useState("");

  const { data, loading, error } = useFetch(
    `/sub-categories?[filters][categories][id][$eq]=${catId}`
  );

  const images = [
    "https://justprint.co.il/cdn/shop/collections/birthday2_1296x.jpg",
    "https://justprint.co.il/cdn/shop/collections/pic_bodedim_1512x.png",
    "https://justprint.co.il/cdn/shop/collections/wedding_3_900x.jpg",
    "https://justprint.co.il/cdn/shop/collections/LOVE_TLV_BAG_1296x.jpg",
    "https://justprint.co.il/cdn/shop/collections/bachelor_3_1296x.jpg",
    "https://justprint.co.il/cdn/shop/collections/hoodie_1080x.png",
    "https://justprint.co.il/cdn/shop/collections/7f32adc402f7f8444aa100def239c274_1080x.jpg"
    // Add more image URLs as needed
  ];

  useEffect(() => {
    // Function to change the image
    const changeImage = () => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setCurrentImage(images[randomIndex]);
    };

    // Change image immediately on component mount
    changeImage();

    // Set up interval to change image every 5 seconds (adjust as needed)
    const intervalId = setInterval(changeImage, 3000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const newFilters = {
      categories: { id: { $eq: catId } },
    };

    if (selectedSubCats.length > 0) {
      newFilters.sub_categories = { id: { $in: selectedSubCats } };
    }

    if (maxPrice < 1000) {
      newFilters.price = { $lte: maxPrice };
    }

    setFilters(newFilters);
  }, [catId, selectedSubCats, maxPrice]);

  const handleChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectedSubCats(
      isChecked
        ? [...selectedSubCats, value]
        : selectedSubCats.filter((item) => item !== value)
    );
  };

  return (
    <div className="products" dir="rtl">
      <div className="left">
        <div className="filterItem">
          <h2>קטגוריות מוצרים</h2>
          {data?.map((item) => (
            <div className="inputItem" key={item.id}>
              <input
                type="checkbox"
                id={item.id}
                value={item.id}
                onChange={handleChange}
              />
              <label htmlFor={item.id}>{item.attributes.title}</label>
            </div>
          ))}
        </div>
        {/* <div className="filterItem">
          <h2>מיון לפי מחיר</h2>
          <div className="inputItem">
            <span>0</span>
            <input
              type="range"
              min={0}
              max={1000}
              onChange={(e) => setMaxPrice(e.target.value)}
            />
            <span>{maxPrice}</span>
          </div>
        </div> */}
        <div className="filterItem">
          <h2>מיין לפי</h2>
          <div className="inputItem">
            <input
              type="radio"
              id="asc"
              value="asc"
              name="price"
              onChange={(e) => setSort("asc")}
            />
            <label htmlFor="asc">מחיר (מהנמוך לגבוה)</label>
          </div>
          <div className="inputItem">
            <input
              type="radio"
              id="desc"
              value="desc"
              name="price"
              onChange={(e) => setSort("desc")}
            />
            <label htmlFor="desc">מחיר (מהגבוה לנמוך)</label>
          </div>
        </div>
      </div>
      <div className="right">
       <img
          className="catImg"
          src={currentImage}
          alt="Category Image"
        />
        <div className="centered-container">
          <List filters={filters} sort={sort} catId={catId} />
        </div>
      </div>
    </div>
  );
};

export default Products;