import React from "react";
import "./Categories.scss";
import { Link } from "react-router-dom";
import pic1 from '../../Media/Frame 21.jpg'
import pic2 from '../../Media/Frame 23.jpg'
import pic5 from '../../Media/Frame 26.jpg'
import pic6 from '../../Media/Frame 27.jpg'
import pic7 from '../../Media/Frame 28.jpg'
import pic8 from '../../Media/Group 1191.jpg'


const Categories = () => {
  return (
    <div className="categories">
      <div className="col">
        <div className="row">
          <img
            src={pic1}
            alt="Woman Category"
          />
          <button>
            <Link className="link" to="/products/5">
              הדפסה על חולצות
            </Link>
          </button>
        </div>
        <div className="row">
          <img
            src={pic2}
            alt=""
          />
          <button>
            <Link to="/products/6" className="link">
             הדפסה על כובעים
            </Link>
          </button>
        </div>
      </div>
      <div className="col">
        <div className="row">
          {" "}
          <img
            src={pic7}
            alt=""
          />
          <button>
            <Link to="/products/7" className="link">
              בגדי ספורט
            </Link>
          </button>
        </div>
      </div>
      <div className="col col-l">
        <div className="row">
          <div className="col">
            <div className="row">
              <img
                src={pic6}
                alt=""
              />
              <button>
                <Link to="/products/8" className="link">
                 בגדי חורף
                </Link>
              </button>
            </div>
          </div>
          <div className="col">
            <div className="row">
              {" "}
              <img
                src={pic5}
                alt=""
              />
              <button>
                <Link to="/products/10" className="link">
                  מוצרי פרסום וקד״ם
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <img
            src={pic8}
            alt=""
          />
          <button>
            <Link to="/products/9" className="link">
               תיקים ממותגים
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Categories;
