import React from 'react';
import './ContactPopUp.scss';

const PopUp = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>{message.title}</h2>
        <p>{message.body}</p>
        <button onClick={onClose}>סגור</button>
      </div>
    </div>
  );
};

export default PopUp;