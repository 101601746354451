import React from "react";
import Card from "../Card/Card";
import useFetch from "../../hooks/useFetch";
import './FeaturedProducts.scss';


// Dynamic import of all images from the Media folder
const importAll = (r) => r.keys().reduce((acc, item) => {
  acc[item.replace('./', '')] = r(item);
  return acc;
}, {});

// Use require.context to import all images from the Media folder
// Adjust the path if necessary
const images = importAll(require.context('../../Media/', false, /\.(png|jpe?g|svg)$/));

const FeaturedProducts = ({ type }) => {
  const { data, loading, error } = useFetch(
    `/products?populate=*&[filters][type][$eq]=${type}`
  );

  const features = [
    { icon: 'highQuality.png', text: "איכות הדפסה גבוהה" },
    { icon: 'discount.png', text: "הנחות בקניה בכמויות" },
    { icon: 'personalized.png', text: "הצעות מותאמות אישית" },
    { icon: 'fastDev.png', text: "משלוחים מהירים " },
    { icon: 'customMade.png', text: "ליווי בתהליך העיצוב" },
    { icon: 'ecoFriendly.png', text: "בדים באיכות גבוהה" }
  ];

  return (
    <div className="featured-products">
      <h1 className="header">למה כדאי לבחור בנו?</h1>
      
      <div className="features">
        {features.map((feature, index) => (
          <div key={index} className="feature">
            <img 
              src={images[feature.icon] || '/placeholder.png'} 
              alt={feature.text} 
              className="icon" 
            />
            <p className="text">{feature.text}</p>
          </div>
        ))}
      </div>

      <p className="description">
        חוגגים אירוע משפחתי משמח כמו בריתה, בת מצווה, בר מצווה, חתונה או יום הולדת משמעותי? היום, אי אפשר לדמיין אירוע ללא מתנה קטנה או מזכרת לאורחים. יותר ויותר אנשים מעדיפים לבחור במוצרי טקסטיל מעוצבים ושימושיים כמתנה ייחודית. תוכלו להדפיס עליהם תמונה מרגשת, ציטוט מעורר השראה, פרטי האירוע, בדיחה פרטית שמבינים רק בני המשפחה, או כל דבר אחר שעולה בדעתכם. אנחנו נדאג שההדפסה תהיה מושלמת ותחזיק מעמד שנים רבות, כך שהאורחים יוכלו להיזכר באירוע המיוחד שלכם לאורך זמן.
      </p>

      <h2 className="catalogue-title">הקטלוג שלנו</h2>
      {error ? (
        <p className="error">אופסי אנחנו כאלו קלאמזי היום , כבר מטפלים בזה</p>
      ) : loading ? (
        <p>Loading...</p>
      ) : (
        <div className="catalogue">
          {data?.map((item) => (
            <Card key={item.id} item={item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default FeaturedProducts;