import React, { useState } from "react";
import "./Product.scss";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartReducer";

const Product = () => {
  const id = useParams().id;
  const [selectedImg, setSelectedImg] = useState("img");
  const [quantity, setQuantity] = useState(1);
  const [addedToCart, setAddedToCart] = useState(false);

  const dispatch = useDispatch();
  const { data, loading, error } = useFetch(`/products/${id}?populate=*`);

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        id: data.id,
        title: data.attributes.title,
        desc: data.attributes.desc,
        price: data.attributes.price,
        img: data.attributes.img.data.attributes.url,
        quantity,
      })
    );
    setAddedToCart(true);
    setTimeout(() => setAddedToCart(false), 2000); // Reset after 2 seconds
  };

  // Function to split description into multiple lines
  const formatDescription = (description) => {
    if (!description) return [];
    return description.split('. ').filter(sentence => sentence.trim() !== '');
  };

  return (
    <div className="product" dir="rtl">
      {loading ? (
        "טוען..."
      ) : (
        <>
          <div className="left">
            <div className="images">
              <img
                src={
                  process.env.REACT_APP_UPLOAD_URL +
                  data?.attributes?.img?.data?.attributes?.url
                }
                alt=""
                onClick={(e) => setSelectedImg("img")}
              />
              <img
                src={
                  process.env.REACT_APP_UPLOAD_URL +
                  data?.attributes?.img2?.data?.attributes?.url
                }
                alt=""
                onClick={(e) => setSelectedImg("img2")}
              />
            </div>
            <div className="mainImg">
              <img
                src={
                  process.env.REACT_APP_UPLOAD_URL +
                  data?.attributes[selectedImg]?.data?.attributes?.url
                }
                alt=""
              />
            </div>
          </div>
          <div className="right">
            <h1>{data?.attributes?.title}</h1>
            <span className="price">{data?.attributes?.price} ₪</span>
            <div className="quantity">
              <button
                onClick={() =>
                  setQuantity((prev) => (prev === 1 ? 1 : prev - 1))
                }
              >
                -
              </button>
              {quantity}
              <button onClick={() => setQuantity((prev) => prev + 1)}>+</button>
            </div>
            <button
              className={`add ${addedToCart ? 'added' : ''}`}
              onClick={handleAddToCart}
            >
              <AddShoppingCartIcon /> {addedToCart ? 'נוסף לסל' : 'הוסף לסל'}
            </button>
            <div className="info">
              {formatDescription(data?.attributes?.description).map((sentence, index) => (
                <p key={index} className="description">{sentence}</p>
              ))}
              {formatDescription(data?.attributes?.desc).map((sentence, index) => (
                <p key={index} className="description">{sentence}</p>
              ))}
            </div>
            <hr />
            <div className="additional-info">
              {/* Additional info can be added here if needed */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Product;