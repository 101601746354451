import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import customMade from '../../Media/customMade.png';

const CustomBanner = () => {
    const [animate, setAnimate] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsVisible(window.innerWidth >= 1070 && window.innerHeight >= 934);
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        const intervalId = setInterval(() => {
            setAnimate(true);
            setTimeout(() => setAnimate(false), 500);
        }, 2500);

        return () => {
            clearInterval(intervalId);
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    if (!isVisible) {
        return null;
    }

    const containerStyle = {
        width: '100vw',
        height: '40vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
    };

    const bannerStyle = {
        width: '1400px',
        height: '225px',
        backgroundColor: '#b7cced',
        padding: '20px',
        borderRadius: '12px',
        fontFamily: '"Noto Sans Hebrew", sans-serif',
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        direction: 'rtl'
    };

    return (
       <div style={containerStyle}>
            <Link to="/contactUs" style={{ textDecoration: 'none', display: 'block' }}>
                <div style={bannerStyle}>
                    <div style={{ marginLeft: '185px' }}>
                        <img src={customMade} alt="סמל הדפסה מותאמת אישית" style={{ width: '160px', height: '160px' }} />
                        <img src={customMade} alt="סמל הדפסה מותאמת אישית" style={{ width: '160px', height: '160px' }} />
                        <img src={customMade} alt="סמל הדפסה מותאמת אישית" style={{ width: '160px', height: '160px' }} />
                    </div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <h3 style={{ fontSize: '35px', fontWeight: 'bold', color: '#292a2b', margin: '0 0 25px 0', textAlign: 'center' }}>
                            לא מצאתם משהו מתאים?
                        </h3>
                        <h3 style={{ fontSize: '25px', fontWeight: 'bold', color: '#292a2b', margin: '0 0 25px 54px', textAlign: 'center' }}>
                            אנחנו מתאימים הדפסות עבורך
                        </h3>
                        <p style={{ fontSize: '22px', color: '#292a2b', margin: '0 0 20px 105px', textAlign: 'center' }}>
                            כיצד אתה מעדיף את הבד שלך?
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '106px' }}>
                            <span style={{ fontSize: '22px', color: '#292a2b', margin: '15px 0 22px 60px' }}>צור קשר איתנו היום</span>
                            <div style={{ 
                                backgroundColor: '#3b82f6',
                                borderRadius: '50%',
                                width: '45px',
                                height: '45px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                                overflow: 'hidden'
                            }}>
                                <svg 
                                    width="30" 
                                    height="30" 
                                    viewBox="0 0 8 24" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        position: 'absolute',
                                        left: animate ? '-10px' : '0',
                                        transition: 'left 0.5s ease-in-out'
                                    }}
                                >
                                    <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default CustomBanner;