import React, { useState } from 'react';
import styles from './ContactUs.module.scss';
import contactUs1 from '../../Media/contactUs1.jpg'
import contactUs2 from '../../Media/contactUs2.jpg'
import ContuctUsPopUp from '../../components/ContactUsPopUp/ContactUsPopUp';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
    setShowPopup(true);
    // Reset form
    setFormData({ name: '', email: '', phone: '', message: '' });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className={styles.contactPage} dir="rtl">
      <h1 className={styles.title}>צור קשר</h1>
      
      <div className={styles.contactContainer}>
        <div className={styles.leftSection}>
          <div className={styles.imageContainer}>
            <img 
              src={contactUs1}
              alt="תיאור התמונה הראשונה" 
              className={styles.contactImage}
            />
            {/* <img 
              src={contactUs2} 
              alt="תיאור התמונה השנייה" 
              className={styles.contactImage}
            /> */}
          </div>
          
          <div className={styles.contactInfo}>
            <h2>ניתן למצוא אותנו כאן</h2>
            <p>כתובת: בן יהודה 218, תל אביב</p>
            <p>טלפון: 03-7984948</p>
            <p>טלפון:0506-922035</p>
            <p>אימייל: contact@justprint.co.il</p>
            <p>שעות פעילות: א'-ה' 9:00-18:00, ו' 9:00-13:00</p>
          </div>
          <div className={styles.imageContainer}>
            {/* <img 
              src={contactUs1}
              alt="תיאור התמונה הראשונה" 
              className={styles.contactImage}
            /> */}
            {/* <img 
              src={contactUs2} 
              alt="תיאור התמונה השנייה" 
              className={styles.contactImage}
            /> */}
          </div>
        </div>
        
        <div className={styles.contactForm}>
          <h2>שלח לנו הודעה</h2>
          <form onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="name">שם מלא:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="email">אימייל:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="phone">מספר טלפון:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="message">הודעה:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className={styles.submitButton}>שלח</button>
          </form>
        </div>
      </div>
            {showPopup && (
        <ContuctUsPopUp 
          message="ההודעה נשלחה בהצלחה. ניצור איתך קשר בהקדם."
          onClose={closePopup}
        />
      )}
      
    </div>
  );
};

export default ContactUs;