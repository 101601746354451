import React, { useState } from 'react';
import './PaymentWindow.scss';

const PaymentWindow = ({ onClose, totalAmount, onPaymentComplete }) => {
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [error, setError] = useState('');

  const validateCardNumber = (number) => {
    return /^[0-9]{16}$/.test(number);
  };

  const validateExpiryDate = (date) => {
    return /^(0[1-9]|1[0-2])\/[0-9]{2}$/.test(date);
  };

  const validateCVV = (cvv) => {
    return /^[0-9]{3,4}$/.test(cvv);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    if (!validateCardNumber(cardNumber)) {
      setError('מספר כרטיס האשראי אינו תקין');
      return;
    }

    if (!validateExpiryDate(expiryDate)) {
      setError('תאריך התפוגה אינו תקין (השתמש בפורמט MM/YY)');
      return;
    }

    if (!validateCVV(cvv)) {
      setError('קוד CVV אינו תקין');
      return;
    }

    // If all validations pass, you would typically process the payment here
    // For this example, we'll simulate a successful payment
    setTimeout(() => {
      onPaymentComplete();
    }, 2000);
  };

  return (
    <div className="payment-window-overlay">
      <div className="payment-window" dir="rtl">
        <h2>הזן פרטי תשלום</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="cardNumber">מספר כרטיס אשראי</label>
            <input
              type="text"
              id="cardNumber"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value.replace(/\D/g, ''))}
              maxLength="16"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="expiryDate">תוקף</label>
            <input
              type="text"
              id="expiryDate"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
              placeholder="MM/YY"
              maxLength="5"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="cvv">CVV</label>
            <input
              type="text"
              id="cvv"
              value={cvv}
              onChange={(e) => setCvv(e.target.value.replace(/\D/g, ''))}
              maxLength="4"
              required
            />
          </div>
          <div className="total-amount">
            <span>סכום לתשלום:</span>
            <span>₪{totalAmount}</span>
          </div>
          <button type="submit">בצע תשלום</button>
        </form>
        <button className="close-button" onClick={onClose}>סגור</button>
      </div>
    </div>
  );
};

export default PaymentWindow;